import React from 'react';
import { Route, Routes } from "react-router-dom";
import Appointment from '../pages/appointment';
import ThankYouPage from '../pages/thankYou';
import TrackAppointment from '../pages/trackAppointment';

const AppRoute = () => {
    
    return (
        <Routes>
            <Route path="/" element={<Appointment />} />
            <Route path="/thank-you" element={<ThankYouPage />} />
            <Route path="/track-appointment" element={<TrackAppointment />} />
        </Routes>
    );
}

export default AppRoute;