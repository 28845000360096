import React, { useState, useEffect } from "react";
import { Carousel } from 'antd';
import { useDispatch } from "react-redux";
import * as imageService from "../services/crmServices";

const ImageSlider = () => {
    var dispatch = useDispatch();
    const [ images, setImages ] = useState([]);

    // Get Image Slider Function
    const fetchImages = () => {
        dispatch(imageService.getImages())
        .then((res) => {
            //console.info(res);
            setImages(res.data);
        })
        .catch((err) => {
            console.info("Get Error Edit State...");
        });
    }

    useEffect(() => {
        fetchImages();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="imageWrapper">
            <div className="card imageCard">
                <div className="card-body">
                    <div className="cardBodyInner">
                        <Carousel effect="fade" autoplay>
                            {images.map((item, key) => {
                                return(
                                    <div className="slideImg" key={key}>
                                        <img src={item.image} className="img-fluid" alt="slide" />
                                    </div>
                                )
                            })}
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ImageSlider;