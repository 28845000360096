import React, { useState } from "react";
import { Button, Select } from 'antd';
import { useDispatch } from "react-redux";
import moment from "moment";
import * as TrackAppointmentService from "../services/trackAppointment"

const { Option } = Select;
const TrackAppointment = () => {
    var dispatch = useDispatch();
    const [loadings, setLoadings] = useState([]);
    const [patientNumber, setPatientNumber] = useState();
    const [currentStatus, setCurrentStatus] = useState([]);
    const [isPatient, setIsPatient] = useState();
    const [selectValue, setSelectValue] = useState('');
    const [ currentPatient, setCurrentPatient] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    

    //Handle Mobile Number
    const handleNumber = (e) => {
        let numberValue = e.target.value;
        
        if (numberValue.length === 10) {
            setPatientNumber(numberValue)
            fetchCurrentStatus(numberValue);
        } else {
            setCurrentPatient('');
            setPatientNumber('');
            setErrorMessage('');
        }
    }

    const onPasteNumber =() => {
        setCurrentPatient('');
        setPatientNumber('');
        setErrorMessage('');
    }

    //Handle Button Loading Spinner
    const enterLoading = (index) => {
        setLoadings((prevLoadings) => {
            const newLoadings = [...prevLoadings];
            newLoadings[index] = true;
            return newLoadings;
        });
        setTimeout(() => {
            setLoadings((prevLoadings) => {
                const newLoadings = [...prevLoadings];
                newLoadings[index] = false;
                return newLoadings;
            });
        }, 500);
    };

    // Fetch Current Status
    const fetchCurrentStatus = (mobileNum) => {
        dispatch(TrackAppointmentService.getCurrentStatus(mobileNum))
          .then((res) => {
            console.info(res);
            const data = res.data;
            if (data && data.records && data.records.length > 0) {
              setCurrentStatus(res.data);
              const currentDate = new Date();
              const recordDate = new Date(data.records[0].date);
              if (currentDate.toDateString() !== recordDate.toDateString()) {
                setErrorMessage(`The booking for the date ${recordDate.toLocaleDateString('en-US')}, center: ${data.records[0].cities.name} has not started yet.`);
              } else {
                setErrorMessage('');
              }
            } else {
              setCurrentStatus([]);
              setErrorMessage('There is no appointment booked with this number. (इस नंबर से कोई अपॉइंटमेंट बुक नहीं किया)');
            }
          })
          .catch((err) => {
            console.info('Get Error Edit State...');
            setErrorMessage('Error occurred while fetching appointment data.');
          });
    };
      

    // Handle Patient Name
    const handleChange = (value) => {
        // console.log({value});
        // fetchCurrentStatus(value);
        let patientStatus = currentStatus.records
        setIsPatient(patientStatus)
        setSelectValue(value);
       
    };

    // Handle Submit
    const handleStatus = () => {
        enterLoading(0);
        
        let selectedValue = selectValue;
        let currentpat = isPatient.find((pat) => pat.name === selectedValue.value)
        fetchCurrentStatus(currentpat.mobile_number);
        //console.log({currentpat})
        setCurrentPatient(currentpat);
    }

    //const isButtonDisabled = !patientNumber || selectValue === '';

    return(
        <div className="trackWrapper">
            <div className="innerWrap">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-6">
                            <div className="card trackCard">
                                <div className="card-header">
                                    <h4 className="card-title">Track Appointment</h4>
                                </div>
                                <div className="card-body">
                                    <div className="formInner">
                                        <div className="mb-5">
                                            <p className="text-center">Please enter your mobile number to track your today's appointment number.</p>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="mobile_num" className="mb-2">Mobile Number</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter your mobile number"
                                                onChange={(e) => handleNumber(e)}
                                                pattern="[0-9]{10}"
                                                maxLength="10"
                                                onKeyPress={(e) => {
                                                    const key = e.key;
                                                    const numericRegex = /^[0-9]*$/;
                                                    if (!numericRegex.test(key)) {
                                                      e.preventDefault();
                                                    }
                                                }}
                                                onPaste={onPasteNumber}
                                                //value={patientNumber}
                                            />
                                        </div>
                                        {patientNumber && currentStatus?.length !== 0 && errorMessage === '' &&
                                            <div className="mb-3">
                                                <label htmlFor="mobile_num" className="mb-2">Patient Name</label>
                                                <Select
                                                    className="customSelect"
                                                    popupClassName="customSelectDrop"
                                                    labelInValue
                                                    style={{
                                                        width: "100%",
                                                        height: 'auto'
                                                    }}
                                                    placeholder="Select Patient"
                                                    onChange={handleChange}
                                                >
                                                    {currentStatus && currentStatus.records.map((item, i) => {
                                                        return (
                                                            <Option value={item.name} key={i}>{item.name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        }
                                        {currentStatus?.length !== 0 && selectValue !== '' &&
                                            <div className="mb-3 text-center">
                                                <Button 
                                                    type="button"
                                                    className="btn btnTrack"
                                                    loading={loadings[0]}
                                                    onClick={() => handleStatus()}
                                                    disabled={!patientNumber || selectValue === '' || errorMessage !== ''}
                                                >
                                                    Track Appointment
                                                </Button>
                                            </div>
                                        }
                                    </div>

                                    {errorMessage && (
                                        <div className="error-message" style={{color: "#FF0000"}}>
                                            {errorMessage}
                                        </div>
                                    )}
                                     {currentPatient && currentPatient.status==="2"?(
                                        currentPatient &&
                                        <div className="resultInner text-center mt-4">
                                            {/* <h5 className="resultTitle">Result:</h5> */}
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="slotList text-center">
                                                        <span className="resultText">Your Appoinment Got Cancelled</span>
                                                        
                                                    </div>
                                                </div>
                                               
                                            </div>
                                        </div>
                                    
                                     ):(
                                        currentPatient &&
                                        <div className="resultInner text-center mt-4">
                                            
                                            {/* <h5 className="resultTitle">Result:</h5> */}
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="slotList text-center">
                                                        <span className="resultText">Current Running Slot: </span>
                                                        <span className="resultNumber">{currentStatus?.current_appointment_number}</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="slotList">
                                                        <span className="resultText">Date: </span>
                                                        <span className="resultNumber">{moment(currentStatus?.date).format("DD/MM/YYYY")}</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="slotList">
                                                        <span className="resultText">Name: </span>
                                                        <span className="resultNumber">{currentPatient?.name}</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="slotList">
                                                        <span className="resultText">Center Name: </span>
                                                        <span className="resultNumber">{currentPatient?.cities?.name}</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="slotList">
                                                        <span className="resultText">Your Slot Number: </span>
                                                        <span className="resultNumber">{currentPatient?.slot}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                     )}
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
} 

export default TrackAppointment;