import Http from "../Http";
import { LIVE_URL } from "../Helper";

export function getCurrentStatus(mobile_num) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.get( LIVE_URL + 'api/getTrackRecord?mobile_number=' + mobile_num )
                .then((res) => {
                    return resolve(res);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}