import React, { useState, useEffect } from "react";
import { Form, Steps, notification, Button } from "antd";
import { useDispatch } from "react-redux";
import { IoLocationOutline } from "react-icons/io5";
import { AiOutlineCheck } from 'react-icons/ai';
import { FiUser } from "react-icons/fi";
import StepOne from "./stepOne";
import StepThree from "./stepThree";
import StepFour from "./stepFour";
import AboutWrapper from "./aboutWrapper";
import FeedbackWrapper from "./feedbackWrapper";
import ImageSlider from "./imagesWrapper";
import VideoWrapper from "./videoWrapper";
import SuccessStory from "./successStory";
import * as stateCityService from "../services/stateCity";
import * as appointmentService from "../services/bookAppointment";
import { CheckCircleOutlined } from "@ant-design/icons";
import moment from "moment";

const Appointment = () => {
    const [form] = Form.useForm();
    var dispatch = useDispatch();
    const [activeStep, setActiveStep] = useState(0);
    const [statesApp, setStatesApp] = useState();
    const [loadings, setLoadings] = useState([]);
    const [formInput, setFormInput] = useState({
        city: "",
        appointDate: "",
        pname: "",
        age: "",
        number: "",
        gender: "",
        patientCity: "",
        wnumber: "",
        disease: "",

    });
    console.log(formInput,"formInput")
    const [selectedCity, setSelectedCity] = useState(null);
    const [isSlot, setIsSlot] = useState('');
    const [isDate, setIsdate] = useState('');
    const [selectedCityName, setSelectedCityName] = useState('');

    // Handle Input Function
    const handleInput = (input) => (e) => {
        if (input === "gender") {
            setFormInput({ ...formInput, [input]: e.value });
        } else if (input === "age") {
            setFormInput({ ...formInput, [input]: e });
        } else if (input === 'city') {
            setFormInput({ ...formInput, [input]: e.target.value });
            setSelectedCity(e.target.value);
        }else  if (input === "appointDate") {
        const selectedDate = moment(e.target.value, "DD MMM, YYYY, dddd").format("YYYY-MM-DD");
        console.log("Formatted Date:", selectedDate);
        setFormInput({ ...formInput, [input]: selectedDate });
    } else {
            setFormInput({ ...formInput, [input]: e.target.value });
        }
    };

    const handleSelectedCity = (cityName) => {
        setSelectedCityName(cityName);
    };

    // Fetch State and City of Clinic
    const fetchStateCity = () => {
        dispatch(stateCityService.getStatesCities())
            .then((res) => {
                //console.info(res);
                setStatesApp(res.data);
            })
            .catch((err) => {
                console.info("Get Error Edit State...");
            });
    };

    const handlePatientData = (data) => {
        //console.log(data)
        let formData = {
            ...formInput,
            pname: data.name,
            age: data.age,
            number: data.mobile_number,
            gender: data.gender,
            dob: data.dob,
            wnumber: data.whatsapp_number,
            disease: data.type_of_disease,
            country: data.country,
            stateInfo: data.state,
            cityInfo: data.city,
            address: data.address,
            pin: data.pin_code,
            email: data.email,
            state_new_id: data.state_id,
            city_new_id: data.city_id,
            oldPatient: 1
        }
        form.setFieldsValue(formData);
        setFormInput(formData)
    }

    useEffect(() => {
        fetchStateCity();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setFormInput(formInput);
        //console.warn("propsData",appointData)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    // Handle Loader
    const enterLoading = (index) => {
        setLoadings((prevLoadings) => {
            const newLoadings = [...prevLoadings];
            newLoadings[index] = true;
            return newLoadings;
        });
        setTimeout(() => {
            setLoadings((prevLoadings) => {
                const newLoadings = [...prevLoadings];
                newLoadings[index] = false;
                return newLoadings;
            });
        }, 5000);
    };

    const handleNavigate = () => {
        window.location.reload();
    }
    const handleNextStep = () => {
        setActiveStep(activeStep + 1);
    };

    // Steps
    const steps = [
        {
            title: "Appointment Details",
            content: (
                <StepOne
                    handleInput={handleInput}
                    formInput={formInput}
                    statesApp={statesApp}
                    selectedCity={selectedCity}
                    onSelectCity={handleSelectedCity}
                    onNextStep={handleNextStep}
                />
            ),
            icon: <IoLocationOutline />,
            fields: ["city", "appointDate"],
        },
        {
            title: "Patient Information",
            content: (
                <StepThree
                    handleInput={handleInput}
                    formInput={formInput}
                    handlePatientData={handlePatientData}
                />
            ),
            icon: <FiUser />,
            fields: ["pname", "age", "number", "gender", "wnumber"],
        },
        {
            title: "Thank You",
            content: (
                <StepFour 
                    isSlot={isSlot}
                    isDate={isDate}
                    selectedCityName={selectedCityName}
                />
            ),
            icon: <AiOutlineCheck />,
        },
    ];

    // Step Items
    const items = steps.map((item) => ({
        key: item.title,
        title: item.title,
        icon: item.icon,
    }));

    // Next Function
    // const handleNext = async () => {
    //     // window.scrollTo(0, 0);
    //     try {
    //         await form.validateFields([
    //             "city",
    //             "appointDate",
    //         ]);
    //         setActiveStep(activeStep + 1);
    //     } catch {
    //         console.log("Validation error");
    //     }
    // };

    // Function Back
    const handleBack = () => {
        window.scrollTo(0, 0);
        let formInputData = { ...formInput }
        formInputData["appointment_date"] = null
        setFormInput(formInputData);
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    // Finish Function
    const onFinish = async (values) => {
        try {
            await form.validateFields([
                "pname",
                "age",
                "number",
                "gender",
                "wnumber",
                "patientCity",
            ]);
            enterLoading(0);

            const data = {
                name: formInput.pname,
                age: formInput.age,
                mobile_number: formInput.number,
                gender: formInput.gender,
                whatsapp_number: formInput.wnumber,
                type_of_disease: formInput.disease,
                patientCity: formInput.patientCity,
                user_city: formInput.city,
                date: formInput.appointDate
            };

            dispatch(appointmentService.bookAppointment(data))
                .then((res) => {
                    //console.log({"response": res});
                    setIsSlot(res.slot);
                    setIsdate(res.date);
                    // return
                    // Submit Notification
                    notification.open({
                        message: "Appointment Booked!",
                        description: "You have successfully booked appointment.",
                        icon: (
                            <CheckCircleOutlined
                                style={{
                                    color: "#108ee9",
                                }}
                            />
                        ),
                    });
                    setActiveStep(activeStep + 1);
                    // Clear Form Fields
                    // window.location.reload();
                })
                .catch((err) => {
                    notification.open({
                        message: "Booking Failed!",
                        description: "Booking failed due to maintainence issue",

                    });
                });
        } catch {
            console.log("Validation error");
        }

    };

    // onFinishFailed Function
    const onFinishFailed = (errorInfo) => {
        notification.open({
            message: "Booking Failed!",
            description: "Booking failed due to maintainence issue",

        });
    };

    // const isNextButtonDisabled = () => {
      
    //     const selectedCity = form.getFieldValue("city");
    //     const appointDate = form.getFieldValue("appointDate");

    //     if (selectedCity && appointDate) {
    //       const city = statesApp.find((city) => city.city_id === selectedCity);
      
    //       if (city) {
    //         const currentDate = moment();
    //         const validAppointmentDates = city.appointment_dates.filter((date) =>
    //           moment(date, "DD MMM, YYYY, dddd").isSameOrAfter(currentDate, "day")
    //         );
      
    //         return !validAppointmentDates.includes(appointDate);
    //       }
    //     }
      
    //     return true;
    // };

    return (
        // Appointment Wrapper Start

        <div className="appointmentWrapper">
            <div className="innerWrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <AboutWrapper />
                        </div>
                    </div>

                    <div className="stepperWrapper">
                        <div className="row">
                            <div className="col-md-12 col-lg-5">
                                <div className="drInfoCard">
                                    {activeStep === 0 ? (
                                        <VideoWrapper />
                                    ) : activeStep === 1 ? (
                                        <ImageSlider />
                                    ) : activeStep === 2 ? (
                                        <FeedbackWrapper />
                                    ) : activeStep === 3 ? (
                                        <SuccessStory />
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-7">
                                <div className="stepper">
                                    <div className="card stepCard">
                                        <div className="card-body">
                                            <div className="stepInner">
                                                <div className="stepHeader">
                                                    <Steps
                                                        current={activeStep}
                                                        items={items}
                                                        labelPlacement="vertical"
                                                    />
                                                </div>
                                                <div className="stepForm">
                                                    <Form
                                                        name="stepForm"
                                                        onFinishFailed={onFinishFailed}
                                                        autoComplete="off"
                                                        className="customForm"
                                                        layout="vertical"
                                                        initialValues={
                                                            formInput
                                                        }
                                                        form={form}
                                                    >
                                                        <div className="step-content">
                                                            {steps[activeStep].content}
                                                            {statesApp?.length === 0 ? "" : <div className="btnGroup">
                                                                {activeStep === 0 || activeStep === 2 ? (
                                                                    ""
                                                                ) : (
                                                                    <button
                                                                        className="btn btnBack me-3"
                                                                        onClick={handleBack}
                                                                    >
                                                                        Back
                                                                    </button>
                                                                )}

                                                                {/* {activeStep < steps.length - 2 && (
                                                                    <button
                                                                        type="button"
                                                                        className="btn btnNext ms-auto"
                                                                        onClick={(e) => handleNext(e)}
                                                                        disabled={
                                                                            (activeStep === 0 && isNextButtonDisabled()) || loadings[0]
                                                                        }
                                                                    >
                                                                        Next
                                                                    </button>
                                                                )} */}

                                                                {activeStep === 1 && (
                                                                    // </button>
                                                                    <Button
                                                                        className="btn btnPay"
                                                                        onClick={onFinish}
                                                                        loading={loadings[0]}
                                                                    >
                                                                        Proceed
                                                                    </Button>
                                                                )}

                                                                {activeStep === 2 && (
                                                                    <button
                                                                        className="btn btnNext btnFinish ms-auto"
                                                                        onClick={handleNavigate}
                                                                    >
                                                                        Back to homepage
                                                                    </button>
                                                                )}
                                                            </div>}
                                                        </div>
                                                    </Form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        // Patient Overview Modal End

        // Appointment Wrapper End
    );
};

export default Appointment;