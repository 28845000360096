import Http from "../Http";
import { LIVE_URL } from "../Helper";

export function getStatesCities() {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.get( LIVE_URL + 'api/getStateCity')
                .then((res) => {
                    return resolve(res);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}


export function getStates() {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.get( LIVE_URL + 'api/getState')
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}

export function getCities(stateId) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.get( LIVE_URL + 'api/getCity/'+stateId)
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}