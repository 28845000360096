import React, { useState, useEffect } from "react";
import { Carousel } from 'antd';
import { BsChatSquareQuote } from 'react-icons/bs';
import { useDispatch } from "react-redux";
import * as testimonialService from "../services/crmServices";

const FeedbackWrapper = () => {
    var dispatch = useDispatch();
    const [ testimonial, setTestimonial ] = useState([]);

    // Get Testimonial Function
    const fetchTestimonial = () => {
        dispatch(testimonialService.getTestimonials())
        .then((res) => {
            //console.info(res);
            setTestimonial(res.data);
        })
        .catch((err) => {
            console.info("Get Error Edit State...");
        });
    }

    useEffect(() => {
        fetchTestimonial();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        
        // Testimonial Wrapper Start
        <div className="feedbackWrapper">
            <div className="card feedbackCard">
                <div className="card-header">
                    <h4 className="card-title">Our Happy Clients</h4>
                </div>
                <div className="card-body">
                    <div className="cardBodyInner">
                        <Carousel autoplay effect="fade" dots={false}>
                            {testimonial.map((item, key) => {
                                return(
                                    <div className="clientFeedSlide" key={key}>
                                        <div className="topRow">
                                            <p className="icon">
                                                <BsChatSquareQuote />
                                            </p>
                                            <h4 className="clientName">{item.name}</h4>
                                            <h5 className="designation">{item.subtitle}</h5>
                                        </div>
                                        <div className="card clientFeedCard">
                                            <div className="card-body">
                                                <p>{item.content}</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                            
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
        // Testimonial Wrapper End
    );
}

export default FeedbackWrapper;