import React from 'react'

const Index = () => {
  return (
    <div className='footerWrapper'>
        <div className='copyrightTextWrapper'>
            <p className="copyrightText"> Copyright © 2023 Gangwal Homoeopathy Research Hospital Powered by ALEA IT</p>
        </div>
    </div>
  )
}

export default Index