import { BrowserRouter } from "react-router-dom";
import AppRoute from "../src/routes/routes";
import Header from "./components/Header/index";
import Footer from "./components/Footer/index";
import "./styles/style.scss";

function App() {
    return (
        <BrowserRouter>
            <Header />
                <AppRoute />
            <Footer />
        </BrowserRouter>
    );
}

export default App;
